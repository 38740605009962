import React, { useState } from "react";
import {
  Card,
  Col,
  Row,
  Radio,
  Form,
  Input,
  notification,
  Tooltip,
  DatePicker,
} from "antd";
import {
  IPrice,
  IVoucher,
  IConfirmation,
  IPackage,
} from "../../../@types/Accept";
import { createConfirmation } from "../../../services/Accept";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { phoneMask } from "../../../util/phoneMask";
import { useTranslation } from "react-i18next";
import ResumeCardInfo from "../../../components/ResumeCardInfo";
import moment from "moment-timezone";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
moment.tz.setDefault(timeZone);
interface Step3Props {
  prevStep: () => void;
  nextStep: () => void;
  setVoucher: any;
  totalValue: number;
  totalDiscount: number;
  price: IPrice;
  voucher: IVoucher;
  confirmation: IConfirmation;
  setConfirmation: any;
  packageOptions: any;
  current: number;
  setTotalDiscount: any;
  packages: IPackage[];
}
const Step3: React.FC<Step3Props> = ({
  prevStep,
  nextStep,
  setVoucher,
  setTotalDiscount,
  totalValue,
  totalDiscount,
  price,
  voucher,
  confirmation,
  setConfirmation,
  packageOptions,
  current,
  packages,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const location = useLocation();
  const { token } = queryString.parse(location.search);
  const [telephoneStatus, setTelephoneStatus] = useState<null | any>(undefined);
  const [telephoneHelp, setTelephoneHelp] = useState<null | any>(undefined);
  const [loading, setLoading] = useState(false);

  function changePhone(field_name: any, field_value: any) {
    const phoneNumber = phoneMask(field_value);
    if (phoneNumber) {
      setTelephoneStatus("success");
      setTelephoneHelp(undefined);
      setConfirmation({ ...confirmation, [field_name]: phoneNumber });
      form.setFieldsValue({ confirmation: { [field_name]: phoneNumber } });
    } else {
      setTelephoneStatus("error");
      setTelephoneHelp(t("invalid_phone"));
    }
  }

  function showNotificationError(message: any) {
    notification.error({
      message: t("error"),
      description: message,
    });
  }

  async function beforeConfirm() {
    const validated = await form.validateFields();
    if (validated && ["success", undefined].includes(telephoneStatus)) {
      confirmPurchase();
    } else {
      notification.error({
        message: t("error"),
        description: t("fill_fields"),
      });
    }
  }

  function confirmPurchase() {
    setLoading(true);
    createConfirmation(confirmation, token)
      .then(({ data }) => {
        setConfirmation({
          organization_id: undefined,
          voucher_id: undefined,
          voucher_name: undefined,
          form_of_payment: "billet",
          organization_name: "",
          social_name: "",
          social_revenue: "",
          organization_telephone: "",
          cep: "",
          state: undefined,
          city: "",
          neighborhood: "",
          street: "",
          number: undefined,
          complement: "",
          subscriber_name: "",
          subscriber_email: "",
          financial_name: "",
          financial_email: "",
          financial_telephone: "",
          observation: "",
          tiflux_license: 0,
          inventory_monitoring_remote: 0,
          monitoring_advanced: 0,
          splashtop: 0,
          whatsapp: 0,
          vnc: 0,
          ti_peer_to_peer: 0,
          platform_chat: 0,
          whitelabel: false,
          tiflux_api: false,
          attendance_name: "",
          ticket_feedback: "",
          expiration_date: moment().add(2, "days"),
          expiration_date_unique: moment().add(2, "days"),
          form_of_payment_unique: "credit_card",
          recurrent_payment_condition: "monthly",
        });
        form.resetFields();
        setVoucher({
          id: 0,
          name: "",
          description: "",
          active: false,
          tiflux_license_discount: 0,
          whatsapp_discount: 0,
          splashtop_discount: 0,
          ti_peer_to_peer_discount: 0,
          vnc_discount: 0,
          platform_chat_discount: 0,
          discount_months: 0,
          package_item_discount: 0,
          expiration_date: "",
          error: "",
        });
        nextStep();
      })
      .catch(({ response }) => {
        setLoading(false);
        switch (response.status) {
          case 403:
            showNotificationError(t("no_permission"));
            break;
          case 0:
            showNotificationError(t("no_connection"));
            break;
          case 422:
            var { data } = response;
            for (let key in data) {
              for (let message_id in data[key]) {
                showNotificationError(t(data[key][message_id]));
              }
            }
            break;
          default:
            showNotificationError(t("error"));
            break;
        }
      });
  }

  function disabledDate(current: any) {
    return current && current < moment().startOf("day");
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={beforeConfirm}
      initialValues={{ confirmation }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={15} md={15}>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={24}>
              <Card
                title={
                  <>
                    <Tooltip title={t("payment_tooltip")}>
                      <QuestionCircleOutlined
                        style={{ color: "#31a4dd", float: "right" }}
                      />
                    </Tooltip>
                    {t("payment")}
                  </>
                }
              >
                <Row gutter={[8, 8]}>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      name={["confirmation", "form_of_payment"]}
                      rules={[
                        {
                          required: true,
                          message: t("form_of_payment_required"),
                        },
                      ]}
                      label={t("payment_recurrent")}
                    >
                      <Radio.Group
                        onChange={(e) =>
                          setConfirmation({
                            ...confirmation,
                            form_of_payment: e.target.value,
                          })
                        }
                      >
                        <Radio value={"credit_card"}>{t("credit_card")}</Radio>
                        <Radio value={"billet"}>{t("billet")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      name={["confirmation", "form_of_payment_unique"]}
                      rules={[
                        {
                          required: false,
                          message: t("form_of_payment_required"),
                        },
                      ]}
                      label={t("payment_unique")}
                    >
                      <Radio.Group
                        onChange={(e) =>
                          setConfirmation({
                            ...confirmation,
                            form_of_payment_unique: e.target.value,
                          })
                        }
                      >
                        <Radio value={"credit_card"}>{t("credit_card")}</Radio>
                        <Radio value={"billet"}>{t("billet")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <div className="ant-col ant-form-item-label">
                      <label>{t("packages.payment_expiration_date")}</label>
                    </div>
                    <p>
                      {moment(confirmation.expiration_date).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      name={["confirmation", "expiration_date_unique"]}
                      rules={[
                        {
                          required: false,
                          message: t("expiration_date_unique_required"),
                        },
                      ]}
                      label={t("expiration_date_unique")}
                    >
                      <DatePicker
                        disabledDate={disabledDate}
                        format="DD/MM/YYYY"
                        style={{ width: "100%" }}
                        onChange={(value) =>
                          setConfirmation({
                            ...confirmation,
                            expiration_date_unique: value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      name={["confirmation", "recurrent_payment_condition"]}
                      rules={[
                        {
                          required: true,
                          message: t("recurrent_payment_condition_required"),
                        },
                      ]}
                      label={t("recurrent_payment_condition")}
                    >
                      <Radio.Group
                        onChange={(e) =>
                          setConfirmation({
                            ...confirmation,
                            recurrent_payment_condition: e.target.value,
                          })
                        }
                      >
                        <Radio value={"monthly"}>{t("_monthly")}</Radio>
                        <Radio value={"yeary"}>{t("_yearly")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      name={["confirmation", "unique_payment_text"]}
                      rules={[
                        {
                          required: false,
                          message: t("unique_payment_text_required"),
                        },
                      ]}
                      label={t("condition_payment_unique")}
                    >
                      <Input
                        onBlur={(e) =>
                          setConfirmation({
                            ...confirmation,
                            unique_payment_text: e.target.value,
                          })
                        }
                        placeholder={t("condition_payment_unique_placeholder")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24}>
              <Card title={t("subscriber")}>
                <Row gutter={[8, 8]}>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      name={["confirmation", "subscriber_name"]}
                      label={t("name")}
                      rules={[
                        {
                          required: true,
                          message: t("subscriber_name_required"),
                        },
                      ]}
                    >
                      <Input
                        onBlur={(e) =>
                          setConfirmation({
                            ...confirmation,
                            subscriber_name: e.target.value,
                          })
                        }
                        placeholder={t("enter_subscriber_name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      name={["confirmation", "subscriber_email"]}
                      label={t("email")}
                      rules={[
                        {
                          required: true,
                          message: t("subscriber_email_required"),
                        },
                      ]}
                    >
                      <Input
                        onBlur={(e) =>
                          setConfirmation({
                            ...confirmation,
                            subscriber_email: e.target.value,
                          })
                        }
                        placeholder={t("enter_subscriber_email")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24}>
              <Card title={t("billing")}>
                <Row gutter={[16, 16]} style={{ marginBottom: "0px" }}>
                  <Col xs={8} sm={8} md={8}>
                    <Form.Item
                      name={["confirmation", "financial_name"]}
                      label={t("name")}
                      rules={[
                        {
                          required: true,
                          message: t("financial_name_required"),
                        },
                      ]}
                    >
                      <Input
                        onBlur={(e) =>
                          setConfirmation({
                            ...confirmation,
                            financial_name: e.target.value,
                          })
                        }
                        placeholder={t("enter_financial_name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={8} sm={8} md={8}>
                    <Form.Item
                      name={["confirmation", "financial_email"]}
                      label={t("email")}
                      rules={[
                        {
                          required: true,
                          message: t("financial_email_required"),
                        },
                      ]}
                    >
                      <Input
                        onBlur={(e) =>
                          setConfirmation({
                            ...confirmation,
                            financial_email: e.target.value,
                          })
                        }
                        placeholder={t("enter_financial_email")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={8} sm={8} md={8}>
                    <Form.Item
                      help={telephoneHelp}
                      validateStatus={telephoneStatus}
                      name={["confirmation", "financial_telephone"]}
                      label={t("whatsapp")}
                      rules={[
                        {
                          required: true,
                          message: t("financial_telephone_required"),
                        },
                      ]}
                    >
                      <Input
                        onBlur={(e) =>
                          changePhone("financial_telephone", e.target.value)
                        }
                        maxLength={15}
                        placeholder={t("enter_whatsapp")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Card title={""}>
            <Row gutter={[16, 16]} style={{ marginBottom: "0px" }}>
              <Col xs={12} sm={12} md={12}>
                <Form.Item
                  name={["confirmation", "attendance_name"]}
                  label={t("name_of_seller")}
                  rules={[
                    { required: true, message: t("attedance_name_required") },
                  ]}
                >
                  <Input
                    onBlur={(e) =>
                      setConfirmation({
                        ...confirmation,
                        attendance_name: e.target.value,
                      })
                    }
                    placeholder={t("name_of_seller_placeholder")}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12}>
                <Form.Item
                  name={["confirmation", "tiflux_feedback"]}
                  label={t("tiflux_feedback_required")}
                  rules={[
                    { required: true, message: t("tiflux_feedback_required") },
                  ]}
                >
                  <Input.TextArea
                    rows={1}
                    onBlur={(e) =>
                      setConfirmation({
                        ...confirmation,
                        tiflux_feedback: e.target.value,
                      })
                    }
                    placeholder={t("tiflux_feedback_required_placeholder")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
        <ResumeCardInfo
          setVoucher={setVoucher}
          setTotalDiscount={setTotalDiscount}
          totalValue={totalValue}
          totalDiscount={totalDiscount}
          price={price}
          voucher={voucher}
          confirmation={confirmation}
          backAction={true}
          prevStep={prevStep}
          submitText={t("confirm_purchase")}
          current={current}
          loading={loading}
          setConfirmation={setConfirmation}
          packageOptions={packageOptions}
          packages={packages}
        />
      </Row>
    </Form>
  );
};

export default Step3;
