import { Card, Col, Empty, Form, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  IConfirmation,
  IPackage,
  IPackageItem,
  IPackageItemFull,
  IPrice,
  IVoucher,
} from "../../../@types/Accept";
import ResumeCardInfo from "../../../components/ResumeCardInfo";
import { SingleSelectionPackage } from "../../../components/SingleSelectionPackage";
import { QuantityPackage } from "../../../components/QuantityPackage";

interface Step6Props {
  prevStep: () => void;
  nextStep: () => void;
  setVoucher: React.Dispatch<React.SetStateAction<IVoucher>>;
  totalValue: number;
  totalDiscount: number;
  price: IPrice;
  voucher: IVoucher;
  confirmation: IConfirmation;
  setConfirmation: (confirmation: IConfirmation) => void;
  setPackageOptions: React.Dispatch<React.SetStateAction<IPackageItem[]>>;
  packageOptions: IPackageItem[];
  current: number;
  packages: IPackage[];
  setTotalDiscount: React.Dispatch<React.SetStateAction<number>>;
}

const Step6: React.FC<Step6Props> = ({
  prevStep,
  nextStep,
  setVoucher,
  totalValue,
  totalDiscount,
  setTotalDiscount,
  price,
  voucher,
  confirmation,
  setConfirmation,
  packages,
  setPackageOptions,
  packageOptions,
  current,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  function goNext() {
    setConfirmation({
      ...confirmation,
      package_items: packageOptions,
    });
    nextStep();
  }
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={goNext}
      initialValues={{ packageOptions }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={15} md={15}>
          {packages.length === 0 ? (
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={24}>
                <Card>
                  <Empty />
                </Card>
              </Col>
            </Row>
          ) : (
            packages
              ?.filter((pack: IPackage) => pack.type_packages === 1)
              ?.map((item, index) =>
                item.select_type === 1 ? (
                  <SingleSelectionPackage
                    key={index}
                    packet={item}
                    packageOptions={packageOptions}
                    setPackageOptions={setPackageOptions}
                    setConfirmation={setConfirmation}
                    confirmation={confirmation}
                    amountOptions={
                      packages.filter((packet) => packet.select_type === 1)
                        .length
                    }
                  />
                ) : (
                  <QuantityPackage
                    key={index}
                    packet={item}
                    packageOptions={packageOptions}
                    setPackageOptions={setPackageOptions}
                    setConfirmation={setConfirmation}
                    confirmation={confirmation}
                    requiredField
                  />
                )
              )
          )}
        </Col>
        <ResumeCardInfo
          setVoucher={setVoucher}
          totalValue={totalValue}
          totalDiscount={totalDiscount}
          setTotalDiscount={setTotalDiscount}
          price={price}
          voucher={voucher}
          confirmation={confirmation}
          backAction={true}
          prevStep={prevStep}
          submitText={t("confirm")}
          current={current}
          loading={false}
          setConfirmation={setConfirmation}
          packageOptions={packageOptions}
          packages={packages}
        />
      </Row>
    </Form>
  );
};

export default Step6;
