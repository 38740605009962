import { Card, Col, Form, Radio, Row, Space, Typography } from "antd";
import React, { useEffect } from "react";
import {
  IConfirmation,
  IPackage,
  IPackageItem,
  IPackageItemFull,
} from "../../../src/@types/Accept";
import { useTranslation } from "react-i18next";

interface SingleSelectionPackageProps {
  packet: IPackage;
  setPackageOptions: React.Dispatch<React.SetStateAction<IPackageItem[]>>;
  packageOptions: IPackageItem[];
  setConfirmation: (confirmation: IConfirmation) => void;
  confirmation: IConfirmation;
  amountOptions: number;
}

export const SingleSelectionPackage: React.FC<SingleSelectionPackageProps> = ({
  packet,
  packageOptions,
  setPackageOptions,
  setConfirmation,
  confirmation,
  amountOptions,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setConfirmation({
      ...confirmation,
      package_items: packageOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageOptions]);

  function handleChangeItem(packageItem: IPackageItem) {
    if (packageItem.package_id && packageItem.value !== 0) {
      if (packageOptions?.length === 0) {
        setPackageOptions([
          {
            id: packageItem.id,
            package_id: packageItem.package_id,
            name: packageItem.name,
            value: packageItem.value,
            description: packageItem.description,
            observation: packageItem.observation,
            one_time: packageItem.one_time,
            amount: 1,
          },
        ]);
      } else {
        let options = packageOptions.filter(
          (value: IPackageItem) => value.id !== packageItem.id
        );
        options = options.filter(
          (value: IPackageItem) => value.package_id !== packageItem.package_id
        );

        setPackageOptions([
          ...options,
          {
            id: packageItem.id,
            package_id: packageItem.package_id,
            name: packageItem.name,
            value: packageItem.value,
            description: packageItem.description,
            observation: packageItem.observation,
            one_time: packageItem.one_time,
            amount: 1,
          },
        ]);
      }
    } else if (packageItem.package_id) {
      let options = packageOptions.filter(
        (value: IPackageItem) => value.package_id !== packageItem.package_id
      );

      setPackageOptions(options);
    }
  }

  const validateRequired = () => {
    if (confirmation.is_client) {
      return false;
    } else {
      if (amountOptions === packageOptions.length) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col md={24}>
        <Card bordered={false} title={packet.name}>
          <Form.Item
            style={{
              paddingRight: 16,
            }}
            name={["confirmation", "package_items", packet.name]}
            initialValue={
              packageOptions?.find(
                (option: IPackageItem) => option.package_id === packet.id
              )?.id
            }
            rules={[
              {
                required: validateRequired(),
                message: t("packages.item_required"),
              },
            ]}
          >
            <Radio.Group className="radio-full">
              <Space direction="vertical" className="radio-full">
                {packet.pack_items?.map((packageItem: IPackageItem) => (
                  <Radio
                    key={packageItem.id}
                    style={{
                      color: "#000000D9",
                      display: "block",
                      whiteSpace: "break-spaces",
                    }}
                    className="radio-full"
                    value={packageItem.id}
                    name={packageItem.name}
                    onChange={() => handleChangeItem(packageItem)}
                  >
                    <Typography.Text strong>{packageItem.name}</Typography.Text>
                    {packageItem.value !== 0 && (
                      <span style={{ position: "absolute", right: 0 }}>
                        <Typography.Text
                          style={{
                            color: "#000000D9",
                            paddingRight: 8,
                          }}
                        >
                          {`R$ ${packageItem.value.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`}
                        </Typography.Text>
                        <Typography.Text
                          style={{
                            fontSize: 12,
                            color: "#00000073",
                          }}
                        >
                          {packet.one_time
                            ? t("single_payment")
                            : `${packet.measure}/${t("month")}`}
                        </Typography.Text>
                      </span>
                    )}
                    {packageItem.description && (
                      <Typography.Paragraph
                        style={{
                          marginTop: 2,
                          marginBottom: packageItem.observation ? 2 : -18,
                          color: "#000000A6",
                          display: "block",
                        }}
                      >
                        {packageItem.description}
                      </Typography.Paragraph>
                    )}

                    {packageItem.observation && (
                      <Typography.Paragraph
                        style={{
                          color: "#00000073",
                          display: "block",
                          marginBottom: -16,
                        }}
                      >
                        {packageItem.observation}
                      </Typography.Paragraph>
                    )}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Card>
      </Col>
    </Row>
  );
};
