import React, { useEffect } from "react";
import { Card, Col, Form, InputNumber, Row, Space, Typography } from "antd";
import {
  IConfirmation,
  IPackage,
  IPackageItem,
} from "../../../src/@types/Accept";
import { useTranslation } from "react-i18next";

interface QuantityPackageProps {
  packet: IPackage;
  setPackageOptions: React.Dispatch<React.SetStateAction<IPackageItem[]>>;
  packageOptions: IPackageItem[];
  setConfirmation: (confirmation: IConfirmation) => void;
  confirmation: IConfirmation;
  requiredField?: boolean;
}

export const QuantityPackage: React.FC<QuantityPackageProps> = ({
  packet,
  packageOptions,
  setPackageOptions,
  setConfirmation,
  confirmation,
  requiredField = false,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setConfirmation({
      ...confirmation,
      package_items: packageOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageOptions]);

  function handleChangeItem(
    amount: string | number,
    packageItem: IPackageItem
  ) {
    if (packageItem.package_id && packageItem.amount !== amount) {
      if (packageOptions?.length === 0) {
        setPackageOptions([
          {
            id: packageItem.id,
            package_id: packageItem.package_id,
            name: packageItem.name,
            value: parseFloat((packageItem.value * Number(amount)).toFixed(2)),
            description: packageItem.description,
            observation: packageItem.observation,
            one_time: packageItem.one_time,
            amount: amount,
          },
        ]);
      } else {
        setPackageOptions((lasVal) => {
          let options = lasVal.filter(
            (value) =>
              value.id !== packageItem.id ||
              value.package_id !== packageItem.package_id
          );

          return [
            ...options,
            {
              id: packageItem.id,
              package_id: packageItem.package_id,
              name: packageItem.name,
              value: parseFloat(
                (packageItem.value * Number(amount)).toFixed(2)
              ),
              description: packageItem.description,
              observation: packageItem.observation,
              one_time: packageItem.one_time,
              amount: amount,
            },
          ];
        });
      }
    } else if (amount === 0) {
      const filteredPacks = packageOptions.filter(
        (p: IPackageItem) => p.id !== packageItem.id
      );
      setPackageOptions(filteredPacks);
    }
  }

  const validateRequired = (item: IPackageItem) => {
    const hasItemSelected = packet?.pack_items?.some((p) =>
      packageOptions.some((e) => e.package_id === p.package_id)
    );
    if (hasItemSelected || !requiredField) {
      return Promise.resolve();
    } else {
      return Promise.reject(t("packages.item_required"));
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col md={24}>
        <Card
          bordered={false}
          headStyle={packet.pack_items.length > 1 ? {} : { border: 0 }}
          title={packet.pack_items.length > 1 ? packet.name : ""}
        >
          {packet?.pack_items?.length > 1 ? (
            <Form.Item
              label=""
              name={packet.pack_items.map((p) => p.id)}
              rules={[
                {
                  required: requiredField,
                  validator: (_: any, v: any) => validateRequired(v),
                  message: t("packages.item_required"),
                  validateTrigger: "onFinish",
                },
              ]}
            >
              <Row gutter={[16, 16]}>
                {packet.pack_items?.map((packageItem, index) => (
                  <Col md={24} lg={12} key={index} style={{ paddingBottom: 0 }}>
                    <Card
                      headStyle={{ border: 0, marginBottom: -16 }}
                      title={
                        <Typography.Text
                          style={{
                            fontSize: 14,
                            color: "#000000D9",
                          }}
                          strong
                        >
                          {packageItem.name}
                        </Typography.Text>
                      }
                    >
                      <div>
                        <Typography.Paragraph
                          style={{
                            color: "#000000A6",
                          }}
                        >
                          {packageItem.description}
                        </Typography.Paragraph>
                        <Row
                          align="middle"
                          style={{
                            marginTop: 8,
                            marginBottom: packageItem.observation ? 8 : 0,
                          }}
                          justify="space-between"
                        >
                          <Form.Item
                            noStyle
                            name={[
                              "confirmation",
                              `package-${packet.id}`,
                              `packageItem-${packageItem.id}`,
                            ]}
                            initialValue={
                              packageOptions?.find((a) => {
                                return a.id === packageItem.id;
                              })?.amount || 0
                            }
                          >
                            <InputNumber
                              type={"number"}
                              min={0}
                              precision={0}
                              onChange={(value) =>
                                handleChangeItem(value || 0, packageItem)
                              }
                            />
                          </Form.Item>
                          <div>
                            <Typography.Text
                              style={{
                                color: "#000000D9",
                                paddingRight: 8,
                              }}
                            >
                              {`R$ ${packageItem.value.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}`}
                            </Typography.Text>
                            <Typography.Text
                              style={{
                                fontSize: 12,
                                color: "#00000073",
                              }}
                            >
                              {packet.one_time
                                ? t("single_payment")
                                : `${packet.measure || ""}/${t("month")}`}
                            </Typography.Text>
                          </div>
                        </Row>

                        <Typography.Paragraph
                          style={{
                            color: "#00000073",
                          }}
                        >
                          {packageItem.observation}
                        </Typography.Paragraph>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Form.Item>
          ) : (
            packet?.pack_items?.map((packageItem, index) => (
              <Row key={index}>
                <Col
                  xs={15}
                  style={{
                    textAlign: "left",
                    alignSelf: "center",
                    paddingRight: 12,
                  }}
                >
                  <Space size={8} direction="vertical">
                    <Typography.Text
                      style={{
                        fontSize: 16,
                        color: "#000000D9",
                      }}
                    >
                      {packageItem.name}
                    </Typography.Text>
                    <Typography.Paragraph
                      style={{
                        fontSize: 14,
                        color: "#000000A6",
                      }}
                    >
                      {packageItem.description}
                    </Typography.Paragraph>

                    <Typography.Paragraph
                      style={{
                        fontSize: 14,
                        color: "#00000073",
                      }}
                    >
                      {packageItem.observation}
                    </Typography.Paragraph>
                  </Space>
                </Col>
                <Col
                  xs={9}
                  style={{
                    textAlign: "right",
                    alignSelf: "center",
                    paddingRight: 12,
                  }}
                >
                  <Space size={8}>
                    <Form.Item
                      noStyle
                      name={[
                        "confirmation",
                        `package-${packet.id}`,
                        `packageItem-${packageItem.id}`,
                      ]}
                      initialValue={
                        packageOptions?.find((a) => {
                          return a.id === packageItem.id;
                        })?.amount || 0
                      }
                    >
                      <InputNumber
                        min={0}
                        type={"number"}
                        precision={0}
                        onChange={(value) =>
                          handleChangeItem(value || 0, packageItem)
                        }
                      />
                    </Form.Item>
                    <Typography.Text
                      style={{
                        color: "#000000D9",
                      }}
                    >
                      {`R$ ${packageItem.value.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}
                    </Typography.Text>
                    <Typography.Text
                      style={{
                        fontSize: 12,
                        color: "#00000073",
                      }}
                    >
                      {packet.one_time
                        ? t("single_payment")
                        : `${packet.measure || ""}/${t("month")}`}
                    </Typography.Text>
                  </Space>
                </Col>
              </Row>
            ))
          )}
        </Card>
      </Col>
    </Row>
  );
};
