import React, { useEffect, useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import { getPrice, getPackages } from "../../services/Accept";
import { useLocation } from "react-router-dom";
import { Layout, Card, Col, Row, Steps, Skeleton } from "antd";
import Icon, {
  ShoppingCartOutlined,
  CheckCircleOutlined,
  DollarCircleOutlined,
  SmileOutlined,
  CodeSandboxOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import HeaderLogo from "../../assets/images/header_logo.png";
import { LaptopUser } from "../../components/Icons";
import { IPackage } from "../../@types/Accept";
import moment from "moment-timezone";
import Step6 from "./Step6";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
moment.tz.setDefault(timeZone);

const { Header, Content } = Layout;
const { Step } = Steps;

function Accept() {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);

  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [voucher, setVoucher] = useState({
    id: 0,
    name: "",
    description: "",
    active: false,
    tiflux_license_discount: 0,
    whatsapp_discount: 0,
    splashtop_discount: 0,
    ti_peer_to_peer_discount: 0,
    vnc_discount: 0,
    platform_chat_discount: 0,
    discount_months: 0,
    package_item_discount: 0,
    expiration_date: "",
    error: "",
  });

  const [price, setPrices] = useState<null | any>({
    tiflux_license_user_value: 0,
    inventory_monitoring_remote_user_value: 0,
    monitoring_advanced_user_value: 0,
    splashtop_user_value: 0,
    whatsapp_user_value: 0,
    whitelabel_value: 0,
    tiflux_api_value: 0,
    vnc: 0,
    platform_chat: 0,
    ti_peer_to_peer: 0,
  });

  const [confirmation, setConfirmation] = useState<null | any>({
    organization_id: undefined,
    voucher_id: undefined,
    voucher_name: undefined,
    form_of_payment: "billet",
    organization_name: "",
    social_name: "",
    social_revenue: "",
    organization_telephone: "",
    cep: "",
    state: undefined,
    city: "",
    neighborhood: "",
    street: "",
    number: undefined,
    complement: "",
    subscriber_name: "",
    subscriber_email: "",
    financial_name: "",
    financial_email: "",
    financial_telephone: "",
    observation: "",
    tiflux_license: 0,
    inventory_monitoring_remote: 0,
    monitoring_advanced: 0,
    splashtop: 0,
    whatsapp: 0,
    vnc: 0,
    ti_peer_to_peer: 0,
    platform_chat: 0,
    whitelabel: false,
    tiflux_api: false,
    attendance_name: "",
    ticket_feedback: "",
    expiration_date: moment().add(2, "days"),
    expiration_date_unique: moment().add(2, "days"),
    form_of_payment_unique: "credit_card",
    recurrent_payment_condition: "monthly",
  });

  const [packages, setPackages] = useState([] as IPackage[]);
  const [packageOptions, setPackageOptions] = useState<any>([]);

  useEffect(() => {
    async function loadPackages() {
      let { data } = await getPackages();
      setPackages(data);
    }

    getPrice(location.search).then(({ data }) => {
      setPrices(data.price);
      setVoucher(data.voucher);
      loadPackages();

      const { organization, voucher } = data;
      if (organization) {
        setConfirmation({
          ...confirmation,
          voucher_id: voucher?.id,
          voucher_name: voucher?.name,
          is_client: organization.organization_status,
          organization_id: organization.organization_id,
          organization_token: organization.organization_token,
          tiflux_license: organization.tiflux_license
            ? organization.tiflux_license
            : 0,
          inventory_monitoring_remote: organization.inventory_monitoring_remote
            ? organization.inventory_monitoring_remote
            : 0,
          monitoring_advanced: organization.max_monitoring
            ? organization.max_monitoring
            : 0,
          splashtop: organization.splashtop,
          whatsapp: organization.whatsapp,
          whitelabel: organization.whitelabel,
          tiflux_api: organization.tiflux_api,
          organization_name: organization.organization_name,
          social_name: organization.social_name,
          social_revenue: organization.social_revenue,
          organization_telephone: organization.organization_telephone,
          cep: organization.cep,
          city: organization.city,
          street: organization.street,
          number: organization.number,
          neighborhood: organization.neighborhood,
          state: organization.state,
          complement: organization.complement,
          financial_name: organization.billing_name,
          financial_email: organization.billing_email,
          financial_telephone: organization.billing_phone,
          subscriber_name: organization.subscriber_name,
          subscriber_email: organization.subscriber_email,
        });
      } else if (voucher) {
        setConfirmation({
          ...confirmation,
          voucher_id: voucher.id,
          voucher_name: voucher.name,
        });
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function nextStep() {
    setCurrent(current + 1);
  }

  function prevStep() {
    setCurrent(current - 1);
  }

  function newAutoBuy() {
    setPackageOptions([]);
    setConfirmation({
      ...confirmation,
      according_with_values: false,
      accept_terms: false,
      privacity_terms: false,
    });
    setCurrent(0);
  }

  function onChangeStep(current: any) {
    setCurrent(current);
  }

  function disabledStep3() {
    if (
      confirmation.tiflux_license > 0 &&
      confirmation.platform_chat > 0 &&
      confirmation.organization_name &&
      confirmation.social_name &&
      confirmation.social_revenue &&
      confirmation.organization_telephone &&
      confirmation.city &&
      confirmation.state &&
      confirmation.neighborhood &&
      confirmation.street &&
      confirmation.number &&
      confirmation.cep &&
      current !== 4
    ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <Layout className="layout">
      <Header>
        {/* <div className="sidemenu-logo" /> */}
        <Row>
          <Col flex="1 1 0%"></Col>
          <Col flex="auto">
            <div className="app-logo">
              <img alt="logo" src={HeaderLogo} />
            </div>
          </Col>
        </Row>
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <div
          className="site-layout-content"
          style={{ paddingTop: 24, minHeight: "100vh" }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24}>
              <Card>
                {loading && <Skeleton active />}
                {!loading && (
                  <Steps
                    current={current}
                    type="navigation"
                    onChange={onChangeStep}
                    size="small"
                  >
                    <Step
                      disabled={current === 5}
                      title={t("licenses")}
                      icon={<ShoppingCartOutlined />}
                    />
                    <Step
                      disabled={
                        (confirmation.tiflux_license <= 0 &&
                          confirmation.platform_chat <= 0) ||
                        current === 5
                      }
                      title={t("packages.multi")}
                      icon={<CodeSandboxOutlined />}
                    />
                    <Step
                      disabled={
                        (confirmation.tiflux_license <= 0 &&
                          confirmation.platform_chat <= 0) ||
                        current === 5
                      }
                      title={t("implantation")}
                      icon={<Icon component={LaptopUser} />}
                    />
                    <Step
                      disabled={
                        (confirmation.tiflux_license <= 0 &&
                          confirmation.platform_chat <= 0) ||
                        (current < 3 &&
                          packageOptions.length !== packages.length)
                      }
                      title={t("my_data")}
                      icon={<CheckCircleOutlined />}
                    />
                    <Step
                      title={t("billing")}
                      disabled={disabledStep3()}
                      icon={<DollarCircleOutlined />}
                    />
                    <Step
                      disabled={true}
                      title={t("finished")}
                      icon={<SmileOutlined />}
                    />
                  </Steps>
                )}
              </Card>
            </Col>
          </Row>
          {loading && (
            <Row gutter={[16, 16]}>
              <Col xs={15} sm={15} md={15}>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={24} md={24}>
                    <Card>
                      <Skeleton active />
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Card>
                      <Skeleton active />
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Card>
                      <Skeleton active />
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Card>
                      <Skeleton active />
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Card>
                      <Skeleton active />
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Card>
                      <Skeleton active />
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xs={9} sm={9} md={9}>
                <Card>
                  <Skeleton active />
                </Card>
              </Col>
            </Row>
          )}
          {!loading && (
            <div>
              {current === 0 && (
                <Step1
                  nextStep={nextStep}
                  setConfirmation={setConfirmation}
                  setTotalValue={setTotalValue}
                  setTotalDiscount={setTotalDiscount}
                  totalValue={totalValue}
                  totalDiscount={totalDiscount}
                  price={price}
                  voucher={voucher}
                  confirmation={confirmation}
                  current={current}
                  packageOptions={packageOptions}
                  setVoucher={setVoucher}
                  packages={packages}
                />
              )}
              {current === 3 && (
                <Step2
                  prevStep={prevStep}
                  nextStep={nextStep}
                  totalValue={totalValue}
                  setTotalValue={setTotalValue}
                  totalDiscount={totalDiscount}
                  setTotalDiscount={setTotalDiscount}
                  price={price}
                  voucher={voucher}
                  confirmation={confirmation}
                  setConfirmation={setConfirmation}
                  current={current}
                  packageOptions={packageOptions}
                  setVoucher={setVoucher}
                  packages={packages}
                />
              )}
              {current === 1 && (
                <Step5
                  prevStep={prevStep}
                  nextStep={nextStep}
                  totalValue={totalValue}
                  totalDiscount={totalDiscount}
                  setTotalDiscount={setTotalDiscount}
                  price={price}
                  voucher={voucher}
                  confirmation={confirmation}
                  setConfirmation={setConfirmation}
                  current={current}
                  packageOptions={packageOptions}
                  setPackageOptions={setPackageOptions}
                  packages={packages}
                  setVoucher={setVoucher}
                />
              )}
              {current === 2 && (
                <Step6
                  prevStep={prevStep}
                  nextStep={nextStep}
                  totalValue={totalValue}
                  totalDiscount={totalDiscount}
                  setTotalDiscount={setTotalDiscount}
                  price={price}
                  voucher={voucher}
                  confirmation={confirmation}
                  setConfirmation={setConfirmation}
                  current={current}
                  packageOptions={packageOptions}
                  setPackageOptions={setPackageOptions}
                  packages={packages}
                  setVoucher={setVoucher}
                />
              )}
              {current === 4 && (
                <Step3
                  prevStep={prevStep}
                  nextStep={nextStep}
                  totalValue={totalValue}
                  totalDiscount={totalDiscount}
                  price={price}
                  voucher={voucher}
                  confirmation={confirmation}
                  setConfirmation={setConfirmation}
                  current={current}
                  packageOptions={packageOptions}
                  setVoucher={setVoucher}
                  setTotalDiscount={setTotalDiscount}
                  packages={packages}
                />
              )}
              {current === 5 && <Step4 newAutoBuy={newAutoBuy} />}
            </div>
          )}
        </div>
      </Content>
    </Layout>
  );
}

export default Accept;
