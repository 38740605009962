import React, { useEffect } from "react";
import ResumeCardInfo from "../../../components/ResumeCardInfo";
import { Card, Col, Row, InputNumber, Form, Tooltip } from "antd";
import {
  IPrice,
  IVoucher,
  IConfirmation,
  IPackage,
} from "../../../@types/Accept";
import { useTranslation } from "react-i18next";

const { Meta } = Card;

interface Step1Props {
  nextStep: () => void;
  setVoucher: React.Dispatch<React.SetStateAction<IVoucher>>;
  setConfirmation: any;
  setTotalValue: any;
  setTotalDiscount: any;
  totalValue: number;
  totalDiscount: number;
  price: IPrice;
  voucher: IVoucher;
  confirmation: IConfirmation;
  current: number;
  packageOptions: any;
  packages: IPackage[];
}
const Step1: React.FC<Step1Props> = ({
  nextStep,
  setVoucher,
  setConfirmation,
  setTotalValue,
  setTotalDiscount,
  totalValue,
  totalDiscount,
  price,
  voucher,
  confirmation,
  current,
  packageOptions,
  packages,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    const total =
      (confirmation.tiflux_license
        ? price.tiflux_license_user_value * confirmation.tiflux_license
        : 0) +
      (confirmation.platform_chat
        ? price.platform_chat * confirmation.platform_chat
        : 0) +
      (confirmation.whatsapp
        ? price.whatsapp_user_value * confirmation.whatsapp
        : 0) +
      (confirmation.vnc ? price.vnc * confirmation.vnc : 0) +
      (confirmation.ti_peer_to_peer
        ? price.ti_peer_to_peer * confirmation.ti_peer_to_peer
        : 0) +
      (confirmation.splashtop
        ? price.splashtop_user_value * confirmation.splashtop
        : 0);

    setTotalValue(total);

    if (!voucher || voucher.error) {
      setTotalDiscount(null);
    } else {
      const totalDisc =
        (price.tiflux_license_user_value *
          confirmation.tiflux_license *
          voucher.tiflux_license_discount) /
          100 +
        (price.platform_chat *
          confirmation.platform_chat *
          voucher?.platform_chat_discount) /
          100 +
        (price.vnc * confirmation.vnc * voucher?.vnc_discount) / 100 +
        (price.ti_peer_to_peer *
          confirmation.ti_peer_to_peer *
          voucher?.ti_peer_to_peer_discount) /
          100 +
        (price.splashtop_user_value *
          confirmation.splashtop *
          voucher.splashtop_discount) /
          100 +
        (price.whatsapp_user_value *
          confirmation.whatsapp *
          voucher.whatsapp_discount) /
          100;

      setTotalDiscount(totalDisc);
    }
  }, [confirmation, price, setTotalDiscount, setTotalValue, voucher]);

  // useEffect(() => {
  //   if (!voucher || voucher.error) {
  //     return;  //   }

  // }, [confirmation, price, setTotalDiscount, voucher]);

  useEffect(() => {
    form.setFieldsValue({ confirmation });
  }, [confirmation, form]);

  return (
    <Form form={form} onFinish={nextStep}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={15} md={15}>
          <Row gutter={[8, 8]}>
            <Col xs={24}>
              <Card>
                <Row>
                  <Col
                    xs={15}
                    style={{ textAlign: "left", alignSelf: "center" }}
                  >
                    <Meta
                      title={t("platform_chat")}
                      description={t("platform_chat_license_description")}
                    />
                  </Col>
                  <Col
                    xs={9}
                    style={{
                      textAlign: "right",
                      alignSelf: "center",
                      paddingRight: 12,
                    }}
                  >
                    <Form.Item
                      initialValue={confirmation.platform_chat || 0}
                      noStyle
                      name={["confirmation", "platform_chat"]}
                    >
                      <InputNumber
                        type={"number"}
                        min={0}
                        onChange={(value) =>
                          setConfirmation({
                            ...confirmation,
                            platform_chat: value,
                          })
                        }
                        precision={0}
                      />
                    </Form.Item>
                    <span style={{ paddingLeft: 8, paddingRight: 8 }}>
                      R${" "}
                      {price?.platform_chat.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                    <span
                      style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.45)" }}
                    >
                      {t("user_per_month")}
                    </span>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Card>
                <Row>
                  <Col
                    xs={15}
                    style={{ textAlign: "left", alignSelf: "center" }}
                  >
                    <Meta
                      title={t("tiflux_base")}
                      description={t("tiflux_users_description")}
                    />
                  </Col>
                  <Col
                    xs={9}
                    style={{
                      textAlign: "right",
                      alignSelf: "center",
                      paddingRight: 12,
                    }}
                  >
                    <Form.Item
                      noStyle
                      initialValue={confirmation.tiflux_license || 0}
                      name={["confirmation", "tiflux_license"]}
                    >
                      <InputNumber
                        type={"number"}
                        min={0}
                        onChange={(value) =>
                          value === 0
                            ? setConfirmation({
                                ...confirmation,
                                tiflux_license: 0,
                                vnc: 0,
                                ti_peer_to_peer: 0,
                                splashtop: 0,
                                whatsapp: 0,
                              })
                            : setConfirmation({
                                ...confirmation,
                                tiflux_license: value,
                              })
                        }
                        precision={0}
                      />
                    </Form.Item>
                    <span style={{ paddingLeft: 8, paddingRight: 8 }}>
                      R${" "}
                      {price?.tiflux_license_user_value.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )}
                    </span>
                    <span
                      style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.45)" }}
                    >
                      {t("user_per_month")}
                    </span>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24}>
              <Card>
                <Row>
                  <Col
                    xs={15}
                    style={{ textAlign: "left", alignSelf: "center" }}
                  >
                    <Meta
                      title={t("whatsapp")}
                      description={t("whatsapp_integration_description")}
                    />
                  </Col>
                  <Tooltip
                    placement="topRight"
                    title={t("maximum_licenses_tooltip")}
                    color="blue"
                  >
                    <Col
                      xs={9}
                      style={{
                        textAlign: "right",
                        alignSelf: "center",
                        paddingRight: 12,
                      }}
                    >
                      <Form.Item
                        initialValue={confirmation.whatsapp || 0}
                        noStyle
                        name={["confirmation", "whatsapp"]}
                      >
                        <InputNumber
                          type={"number"}
                          min={0}
                          max={confirmation.tiflux_license}
                          onChange={(value) =>
                            setConfirmation({
                              ...confirmation,
                              whatsapp: value,
                            })
                          }
                          precision={0}
                        />
                      </Form.Item>
                      <span style={{ paddingLeft: 8, paddingRight: 8 }}>
                        R${" "}
                        {price?.whatsapp_user_value.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                      <span
                        style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.45)" }}
                      >
                        {t("user_per_month")}
                      </span>
                    </Col>
                  </Tooltip>
                </Row>
              </Card>
            </Col>
            <Col xs={24}>
              <Card>
                <Row>
                  <Col
                    xs={15}
                    style={{ textAlign: "left", alignSelf: "center" }}
                  >
                    <Meta
                      title={t("remote_access_vnc")}
                      description={t("remote_access_vnc_description")}
                    />
                  </Col>
                  <Tooltip
                    placement="topRight"
                    title={t("maximum_licenses_tooltip")}
                    color="blue"
                  >
                    <Col
                      xs={9}
                      style={{
                        textAlign: "right",
                        alignSelf: "center",
                        paddingRight: 12,
                      }}
                    >
                      <Form.Item
                        initialValue={confirmation.vnc || 0}
                        noStyle
                        name={["confirmation", "vnc"]}
                      >
                        <InputNumber
                          type={"number"}
                          min={0}
                          max={confirmation.tiflux_license}
                          onChange={(value) =>
                            setConfirmation({
                              ...confirmation,
                              vnc: value,
                            })
                          }
                          precision={0}
                        />
                      </Form.Item>
                      <span style={{ paddingLeft: 8, paddingRight: 8 }}>
                        R${" "}
                        {price?.vnc.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                      <span
                        style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.45)" }}
                      >
                        {t("user_per_month")}
                      </span>
                    </Col>
                  </Tooltip>
                </Row>
              </Card>
            </Col>
            <Col xs={24}>
              <Card>
                <Row>
                  <Col
                    xs={15}
                    style={{ textAlign: "left", alignSelf: "center" }}
                  >
                    <Meta
                      title={t("remote_access_ti_peer_to_peer")}
                      description={t(
                        "remote_access_ti_peer_to_peer_description"
                      )}
                    />
                  </Col>
                  <Tooltip
                    placement="topRight"
                    title={t("maximum_licenses_tooltip")}
                    color="blue"
                  >
                    <Col
                      xs={9}
                      style={{
                        textAlign: "right",
                        alignSelf: "center",
                        paddingRight: 12,
                      }}
                    >
                      <Form.Item
                        noStyle
                        initialValue={confirmation.ti_peer_to_peer || 0}
                        name={["confirmation", "ti_peer_to_peer"]}
                      >
                        <InputNumber
                          type={"number"}
                          min={0}
                          max={confirmation.tiflux_license}
                          onChange={(value) =>
                            setConfirmation({
                              ...confirmation,
                              ti_peer_to_peer: value,
                            })
                          }
                          precision={0}
                        />
                      </Form.Item>
                      <span style={{ paddingLeft: 8, paddingRight: 8 }}>
                        R${" "}
                        {price?.ti_peer_to_peer.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                      <span
                        style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.45)" }}
                      >
                        {t("user_per_month")}
                      </span>
                    </Col>
                  </Tooltip>
                </Row>
              </Card>
            </Col>
            <Col xs={24}>
              <Card>
                <Row>
                  <Col
                    xs={15}
                    style={{ textAlign: "left", alignSelf: "center" }}
                  >
                    <Meta
                      title={t("splashtop")}
                      description={t("splashtop_integration_description")}
                    />
                  </Col>
                  <Tooltip
                    placement="topRight"
                    title={t("maximum_licenses_tooltip")}
                    color="blue"
                  >
                    <Col
                      xs={9}
                      style={{
                        textAlign: "right",
                        alignSelf: "center",
                        paddingRight: 12,
                      }}
                    >
                      <Form.Item
                        noStyle
                        initialValue={confirmation.splashtop || 0}
                        name={["confirmation", "splashtop"]}
                      >
                        <InputNumber
                          type={"number"}
                          min={0}
                          max={confirmation.tiflux_license}
                          onChange={(value) =>
                            setConfirmation({
                              ...confirmation,
                              splashtop: value,
                            })
                          }
                          precision={0}
                        />
                      </Form.Item>
                      <span style={{ paddingLeft: 8, paddingRight: 8 }}>
                        R${" "}
                        {price?.splashtop_user_value.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                      <span
                        style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.45)" }}
                      >
                        {t("user_per_month")}
                      </span>
                    </Col>
                  </Tooltip>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <ResumeCardInfo
          totalValue={totalValue}
          totalDiscount={totalDiscount}
          setTotalDiscount={setTotalDiscount}
          price={price}
          voucher={voucher}
          confirmation={confirmation}
          setVoucher={setVoucher}
          prevStep={() => console.log("prev")}
          submitText={t("confirm")}
          current={current}
          setConfirmation={setConfirmation}
          packageOptions={packageOptions}
          packages={packages}
        />
      </Row>
    </Form>
  );
};

export default Step1;
