import React, { useMemo, useState } from "react";
import VoucherDetailsModal from "../VoucherDetailsModal";
import { Col, Button, Card, Row, Form, Checkbox, Input } from "antd";
import { LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  IConfirmation,
  IPrice,
  IVoucher,
  IPackageItem,
  IPackage,
  IPackageItemFull,
} from "../../@types/Accept";
import { findVoucher } from "../../services/Accept";

const { Search } = Input;

interface ResumeCardProps {
  totalValue: number;
  setVoucher: any;
  totalDiscount: number;
  price: IPrice;
  voucher: IVoucher;
  confirmation: IConfirmation;
  backAction?: boolean;
  prevStep: () => void;
  submitText: string;
  current: number;
  loading?: boolean;
  setConfirmation: (confirmation: IConfirmation) => void;
  packageOptions?: IPackageItem[];
  setTotalDiscount?: any;
  setTotalValue?: React.Dispatch<React.SetStateAction<number>>;
  packages?: IPackage[];
}

const ResumeCardInfo: React.FC<ResumeCardProps> = ({
  totalValue,
  setVoucher,
  totalDiscount,
  price,
  voucher,
  confirmation,
  backAction,
  prevStep,
  submitText,
  current,
  loading,
  setConfirmation,
  setTotalDiscount,
  packageOptions,
  packages,
}) => {
  const { t } = useTranslation();
  const [visibleVoucherDetailsModal, setVisibleVoucherDetailsModal] =
    useState(false);
  const [voucherLoading, setVoucherLoading] = useState(false);
  const [voucherError, setVoucherError] = useState("");

  const totalOneTimeValue = packageOptions
    ?.filter((pack) => pack.one_time === true)
    .reduce((totalOneTime, arr) => totalOneTime + arr.value, 0);
  const totalManyTimeValue = packageOptions
    ?.filter((pack) => pack.one_time === false)
    .reduce((totalMany, arr) => totalMany + arr.value, 0);

  totalValue = totalManyTimeValue
    ? totalValue + totalManyTimeValue
    : totalValue;

  function onSearch(value: string) {
    if (value) {
      setVoucherLoading(true);
      findVoucher(value)
        .then(({ data }) => {
          setVoucherError("");
          setVoucherLoading(false);
          if (data && data.id) {
            let vv = data;
            setVoucher(data);
            setConfirmation({
              ...confirmation,
              voucher_id: data.id,
              voucher_name: data.name,
            });
            let discountCalc =
              (price.tiflux_license_user_value *
                confirmation.tiflux_license *
                vv?.tiflux_license_discount) /
                100 +
              (price.platform_chat *
                confirmation.platform_chat *
                vv?.platform_chat_discount) /
                100 +
              (price.vnc * confirmation.vnc * vv?.vnc_discount) / 100 +
              (price.ti_peer_to_peer *
                confirmation.ti_peer_to_peer *
                vv?.ti_peer_to_peer_discount) /
                100 +
              (price.splashtop_user_value *
                confirmation.splashtop *
                vv?.splashtop_discount) /
                100 +
              (price.whatsapp_user_value *
                confirmation.whatsapp *
                vv?.whatsapp_discount) /
                100;
            let packDiscount = 0;
            if (
              vv.pack_discounts?.length > 0 &&
              packageOptions &&
              packageOptions.length > 0
            ) {
              vv.pack_discounts
                .filter((p: IPackageItem) => p.one_time === false)
                .forEach((disc: IPackageItemFull) => {
                  const itemDisc = packageOptions?.find(
                    (p) =>
                      p.id === disc.package_item_id &&
                      p.package_id === disc.package_id
                  );
                  if (itemDisc) {
                    packDiscount += (itemDisc.value * disc.discount) / 100;
                  }
                });
            }

            discountCalc = discountCalc + packDiscount;

            setTotalDiscount(discountCalc);
          } else {
            setVoucher({
              id: 0,
              name: "",
              description: "",
              active: false,
              tiflux_license_discount: 0,
              whatsapp_discount: 0,
              splashtop_discount: 0,
              ti_peer_to_peer_discount: 0,
              vnc_discount: 0,
              platform_chat_discount: 0,
              discount_months: 0,
              package_item_discount: 0,
              expiration_date: "",
              error: "",
            });
            setVoucherLoading(false);
            setVoucherError(t(data.error));
          }
        })
        .catch((e) => {
          console.error(e);
          setVoucherLoading(false);
        });
    }
  }

  const DiscountRow = () =>
    useMemo(() => {
      if (totalDiscount > 0) {
        return (
          <>
            <Row>
              <Col xs={18} sm={18} md={18} style={{ textAlign: "left" }}>
                <p>
                  <strong>{t("discount")} </strong>
                </p>
              </Col>
              <Col xs={6} sm={6} md={6} style={{ textAlign: "right" }}>
                <p>
                  <strong>
                    R$ -
                    {totalDiscount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </strong>
                </p>
              </Col>
            </Row>

            <Row>
              <Col xs={18} sm={18} md={18} style={{ textAlign: "left" }}>
                <p>
                  <strong>{t("total_recurrent_with_discont")} </strong>
                </p>
              </Col>
              <Col xs={6} sm={6} md={6} style={{ textAlign: "right" }}>
                <p>
                  <strong>
                    R${" "}
                    {(totalValue - totalDiscount).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </strong>
                </p>
              </Col>
            </Row>
          </>
        );
      } else {
        return <></>;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalValue, totalDiscount]);

  const UniqueDiscountRow = () => {
    let oneTimeDiscount = 0;
    voucher?.pack_discounts
      ?.filter((p: IPackageItem) => p.one_time === true)
      .forEach((disc: IPackageItemFull) => {
        const itemDisc = packageOptions?.find(
          (p) =>
            p.id === disc.package_item_id && p.package_id === disc.package_id
        );
        if (itemDisc) {
          oneTimeDiscount += (itemDisc.value * disc.discount) / 100;
        }
      });
    return oneTimeDiscount && oneTimeDiscount !== 0 ? (
      <>
        <Row>
          <Col xs={18} sm={18} md={18} style={{ textAlign: "left" }}>
            <p>
              <strong>{t("discount")} </strong>
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} style={{ textAlign: "right" }}>
            {totalOneTimeValue && (
              <p>
                <strong>
                  R$ -
                  {oneTimeDiscount.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </strong>
              </p>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={18} sm={18} md={18} style={{ textAlign: "left" }}>
            <p>
              <strong>{"Total Único com desconto"} </strong>
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} style={{ textAlign: "right" }}>
            {totalOneTimeValue && (
              <p>
                <strong>
                  R${" "}
                  {(totalOneTimeValue - oneTimeDiscount).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </strong>
              </p>
            )}
          </Col>
        </Row>
      </>
    ) : (
      <></>
    );
  };

  const itemPackage = (id: number) => {
    return packages?.find((value) => value.id === id);
  };

  return (
    <Col xs={24} sm={9} md={9}>
      <Card
        title={t("monthly")}
        bordered={false}
        className="resume-card-info"
        actions={current === 3 ? [t("confirm_purchase_message")] : undefined}
        extra={
          backAction
            ? [
                <Button
                  onClick={() => prevStep()}
                  type="text"
                  size="small"
                  key="back-action"
                >
                  {t("back")}
                </Button>,
              ]
            : false
        }
      >
        <Row>
          <Col xs={18} sm={18} md={18} style={{ textAlign: "left" }}>
            {confirmation.tiflux_license >= 1 && (
              <p>{`${t("tiflux_users")} (${confirmation.tiflux_license}x)`}</p>
            )}
            {confirmation.platform_chat >= 1 && (
              <p>{`${t("platform_chat_license")} (${
                confirmation.platform_chat
              }x)`}</p>
            )}
            {confirmation.whatsapp >= 1 && (
              <p>{`${t("whatsapp_integration")} (${
                confirmation.whatsapp
              }x)`}</p>
            )}
            {confirmation.vnc >= 1 && (
              <p>{`${t("remote_access_vnc_license")} (${
                confirmation.vnc
              }x)`}</p>
            )}
            {confirmation.ti_peer_to_peer >= 1 && (
              <p>{`${t("remote_access_ti_peer_to_peer_license")} (${
                confirmation.ti_peer_to_peer
              }x)`}</p>
            )}
            {confirmation.splashtop >= 1 && (
              <p>{`${t("splashtop_integration")} (${
                confirmation.splashtop
              }x)`}</p>
            )}
          </Col>
          <Col xs={6} sm={6} md={6} style={{ textAlign: "right" }}>
            {confirmation.tiflux_license >= 1 && (
              <p>
                R${" "}
                {(
                  price.tiflux_license_user_value * confirmation.tiflux_license
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            )}
            {confirmation.platform_chat >= 1 && (
              <p>
                R${" "}
                {(
                  price.platform_chat * confirmation.platform_chat
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            )}
            {confirmation.whatsapp >= 1 && (
              <p>
                R${" "}
                {(
                  price.whatsapp_user_value * confirmation.whatsapp
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            )}
            {confirmation.vnc >= 1 && (
              <p>
                R${" "}
                {(price.vnc * confirmation.vnc).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            )}
            {confirmation.ti_peer_to_peer >= 1 && (
              <p>
                R${" "}
                {(
                  price.ti_peer_to_peer * confirmation.ti_peer_to_peer
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            )}
            {confirmation.splashtop >= 1 && (
              <p>
                R${" "}
                {(
                  price.splashtop_user_value * confirmation.splashtop
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            )}
          </Col>
        </Row>
        {packageOptions &&
          packageOptions.map(
            (item: IPackageItem) =>
              itemPackage(item.package_id)?.one_time === false &&
              item.value > 0 && (
                <Row key={item.id}>
                  <Col xs={18} sm={18} md={18} style={{ textAlign: "left" }}>
                    <p>
                      {itemPackage(item.package_id)?.select_type === 0
                        ? `${item.name} (${item.amount}x)`
                        : item.name}
                    </p>
                  </Col>
                  <Col xs={6} sm={6} md={6} style={{ textAlign: "right" }}>
                    <p>
                      R${" "}
                      {item.value.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </Col>
                </Row>
              )
          )}
        {/* <Divider style={{ margin: '5px 0'}} /> */}
        <Row>
          <Col xs={18} sm={18} md={18} style={{ textAlign: "left" }}>
            <p>
              <strong>{t("total_recurrent_license")} </strong>
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} style={{ textAlign: "right" }}>
            <p>
              <strong>
                R${" "}
                {totalValue.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </strong>
            </p>
          </Col>
        </Row>
        <DiscountRow />

        {packageOptions &&
          packageOptions.filter(
            (pack) => pack.one_time === true && pack.value > 0
          ).length > 0 && (
            <Card
              title="Cobrança Única"
              bordered={false}
              style={{ marginLeft: -12, marginRight: -12 }}
            >
              {packageOptions &&
                packageOptions.map(
                  (item: IPackageItem) =>
                    itemPackage(item.package_id)?.one_time === true &&
                    item.value > 0 && (
                      <Row key={item.id}>
                        <Col
                          xs={18}
                          sm={18}
                          md={18}
                          style={{ textAlign: "left" }}
                        >
                          <p>{item.name}</p>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          style={{ textAlign: "right" }}
                        >
                          <p>
                            R${" "}
                            {item.value.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </Col>
                      </Row>
                    )
                )}
              <Row>
                <Col xs={18} sm={18} md={18} style={{ textAlign: "left" }}>
                  <p>
                    <strong>{"Total Único"} </strong>
                  </p>
                </Col>
                <Col xs={6} sm={6} md={6} style={{ textAlign: "right" }}>
                  <p>
                    <strong>
                      R${" "}
                      {totalOneTimeValue?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </strong>
                  </p>
                </Col>
              </Row>
              <UniqueDiscountRow />
            </Card>
          )}

        {current === 4 && (
          <Row>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={["confirmation", "voucher_name"]}
                label={t("voucher")}
                extra={t(voucherError)}
              >
                <Search
                  autoComplete="none"
                  disabled={voucherLoading}
                  loading={voucherLoading}
                  value={confirmation.voucher_name}
                  placeholder={t("enter_voucher_code")}
                  onSearch={onSearch}
                  enterButton
                />
              </Form.Item>
            </Col>
            {voucher && voucher.name && (
              <Row style={{ width: "100%" }} justify="space-between">
                <Col
                  xs={19}
                  sm={19}
                  md={19}
                  style={{
                    textAlign: "left",
                    color: voucher.error ? "red" : "",
                  }}
                >
                  <p>*{voucher.error ? voucher.error : voucher.description}</p>
                </Col>
                {!voucher.error && (
                  <Col xs={5} sm={5} md={5}>
                    <Button
                      type="link"
                      style={{ padding: "0px" }}
                      onClick={() => setVisibleVoucherDetailsModal(true)}
                    >
                      {t("details")}
                    </Button>
                  </Col>
                )}
              </Row>
            )}
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={["confirmation", "according_with_values"]}
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(t("according_with_values_required")),
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) =>
                    setConfirmation({
                      ...confirmation,
                      according_with_values: e.target.checked,
                    })
                  }
                >
                  {t("according_with_values")}
                </Checkbox>
              </Form.Item>

              <Form.Item
                name={["confirmation", "accept_terms"]}
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(t("terms_of_use_required")),
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) =>
                    setConfirmation({
                      ...confirmation,
                      accept_terms: e.target.checked,
                    })
                  }
                >
                  {t("terms_of_use")}
                  <Button
                    style={{ padding: "0px" }}
                    href="http://www.tiflux.com.br/termo"
                    target="_blank"
                    type="link"
                    icon={<QuestionCircleOutlined />}
                  />
                </Checkbox>
              </Form.Item>
              <Form.Item
                name={["confirmation", "privacity_terms"]}
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(t("privacity_terms_required")),
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) =>
                    setConfirmation({
                      ...confirmation,
                      privacity_terms: e.target.checked,
                    })
                  }
                >
                  {t("agg_tiflux_terms")}
                  <Button
                    style={{ padding: "0px" }}
                    href="https://www.tiflux.com.br/privacidade"
                    target="_blank"
                    type="link"
                    icon={<QuestionCircleOutlined />}
                  />
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Card>
      <Button
        style={{ borderWidth: 0, marginTop: 8 }}
        disabled={
          (confirmation.tiflux_license <= 0 &&
            confirmation.platform_chat <= 0) ||
          loading
        }
        icon={loading && <LoadingOutlined />}
        type="primary"
        htmlType="submit"
        block
      >
        {submitText}
      </Button>

      <VoucherDetailsModal
        visible={visibleVoucherDetailsModal}
        voucher={voucher}
        handleOk={() => {
          setVisibleVoucherDetailsModal(false);
        }}
      />
    </Col>
  );
};

export default ResumeCardInfo;
